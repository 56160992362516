
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
  },
  props: {
    imageName: {
      type: String,
    },
  },
  emits: ['saveCropImage'],
  setup(props, context) {
    const modal = ref()
    const openModal = () => {
      modal.value.openModal()
    }
    const saveCropImage = () => {
      modal.value.hideModal()
      context.emit('saveCropImage')
    }
    return {
      modal,
      saveCropImage,

      // methods for using from parents
      openModal,
    }
  },
})
