
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import FileAttach from '@/components/shared/utils/FileAttach.vue'
import CropImageModal from '@/components/modals/shared/CropImageModal.vue'
import _ from 'lodash'

export default defineComponent({
  components: {
    TmButton,
    FileAttach,
    CropImageModal,
  },
  props: {
    disabledButtons: {
      type: Boolean,
    },
    imageName: {
      type: String,
      default: 'logo',
    },
    initialImage: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const image = ref(props.initialImage)
    const cropImageModal = ref()
    const showCropImageModal = () => {
      cropImageModal.value.openModal()
    }
    const saveCropImage = () => {
      image.value = require('@/assets/images/text-magic-square-dark.png')
    }
    const deleteCustomImage = () => {
      image.value = ''
    }

    return {
      image,
      cropImageModal,
      showCropImageModal,
      saveCropImage,
      deleteCustomImage,
      kebabCase: _.kebabCase,
    }
  },
})
