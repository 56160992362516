import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "cropImageModal",
    ref: "modal",
    "modal-title": `Crop ${_ctx.imageName}`
  }, {
    "modal-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: require('@/assets/images/crop-logo.png'),
          alt: _ctx.imageName,
          class: "modal-crop-image"
        }, null, 8, _hoisted_2)
      ])
    ]),
    "modal-footer-button": _withCtx(() => [
      _createVNode(_component_tm_button, {
        size: "large",
        color: "blue",
        onClick: _ctx.saveCropImage
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Save " + _toDisplayString(_ctx.imageName), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["modal-title"]))
}