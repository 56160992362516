
import { Form as Validation } from 'vee-validate'
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import CustomLogo from '@/components/pages/auth/CustomLogo.vue'

export default defineComponent({
  components: {
    Validation,
    TmButton,
    TmFormLine,
    PageAuth,
    CustomLogo,
  },
  setup() {
    const name = ref('')
    return {
      name,
    }
  },
})
